'use client';

import { FC, useContext, useEffect, useState } from 'react';
import { useAssistantsQuery } from '@/lib/swr/hooks';
import { SortOrder } from '@unique/shared-library/@generated/graphql';
import { useRouter } from 'next/navigation';
import { ButtonIcon, Spinner } from '@unique/component-library';
import { IconSpaceAccess } from '@unique/icons';
import {
  ClientThemeContext,
  generateContactAdminLink,
  sortAssistants,
} from '@unique/shared-library';

export const SpaceLandingPage: FC = () => {
  // redirect is super slow, so we want to avoid showing the landingpage if we already have the assistant
  const [isRedirecting, setIsRedirecting] = useState(false);
  const router = useRouter();
  const {
    data: assistantsQueryResult,
    isLoading: isLoadingAssistants,
    error,
  } = useAssistantsQuery({
    orderBy: [{ name: SortOrder.Asc }],
  });
  const { supportEmail } = useContext(ClientThemeContext);

  useEffect(() => {
    if (isLoadingAssistants || !assistantsQueryResult?.assistants?.length) return;
    const sortedAssistants = sortAssistants(assistantsQueryResult.assistants);
    setIsRedirecting(true);
    router.push(`/space/${sortedAssistants[0].id}`);
  }, [assistantsQueryResult, isLoadingAssistants, router]);

  const handleClickContactAdmin = () => {
    window.location.href = generateContactAdminLink({
      supportEmail,
      subject: 'Report Issue: User does not have access to any Space.',
      body: 'Report Issue: User does not have access to any Space.',
    });
  };

  if (isLoadingAssistants || isRedirecting || error) {
    return <Spinner wrapperClasses="flex items-center justify-center h-full w-full" />;
  }

  return (
    <div className="bg-surface relative flex h-full flex-col items-center justify-center gap-y-5">
      <IconSpaceAccess />
      <div className="body-2 text-on-background-dimmed max-w-[180px] text-center">
        Request access to Spaces to be able to Chat.
      </div>
      <ButtonIcon onClick={handleClickContactAdmin}>Contact Admin</ButtonIcon>
    </div>
  );
};
